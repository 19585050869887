import { FunctionComponent } from 'react';
import classNames from 'classnames';
import { SocialIcon } from 'react-social-icons';

interface FooterSocialProps {
  className?: string;
}

const FooterSocial: FunctionComponent<FooterSocialProps> = ({ className, ...props }) => {
  const footerSize = {
    display: 'inline-block',
    width: '30px',
    height: '30px',
    overflow: 'hidden',
    verticalAlign: 'middle'
  };
  const classes = classNames('footer-social', className);
  return (
    <div {...props} className={classes}>
      <ul className="list-reset">
        {/*<li>*/}
        {/*  <SocialIcon*/}
        {/*    style={footerSize}*/}
        {/*    network="twitter"*/}
        {/*    url="https://twitter.com/opencashfinance"*/}
        {/*    bgColor="#99A1BA"*/}
        {/*    target="_blank"*/}
        {/*  />*/}
        {/*</li>*/}
        <li>
          <SocialIcon
            style={footerSize}
            network="facebook"
            url="https://www.facebook.com/opencashfinance"
            bgColor="#99A1BA"
            target="_blank"
          />
        </li>
        <li>
          <SocialIcon
            style={footerSize}
            network="linkedin"
            url="https://www.linkedin.com/company/opencash"
            bgColor="#99A1BA"
            target="_blank"
          />
        </li>
      </ul>
    </div>
  );
};

export default FooterSocial;
